import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Modal } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import TreadDepthModal from "./TreadDepthModal"
import i18n from "../../utilities/i18n"

const TreadDepth = ({ flutterApp, vehicle, history }) => {
  const [open, setOpen] = useState(false)
  const hostElementRef = useRef(null)
  const treadDepthScreenRef = useRef(null)

  const openTreadDepthModal = () => {
    setOpen(true)
  }

  const goToServiceScreen = () => {
    history.push({
      pathname: navigationPaths.serviceRequestNew(),
      state: {
        previousFormData: {
          vehicle_id: vehicle && vehicle.id,
          vehicle: vehicle,
          isTireService: true
        }
      }
    })
  }

  useEffect(() => {
    window._treadDepthScreenInit = () => {
      window._treadDepthScreenInit = () => {}
      treadDepthScreenRef.current = window._treadDepthScreen
      treadDepthScreenRef.current.listenOpenModalClick(openTreadDepthModal)
      treadDepthScreenRef.current.listenTireServiceClick(goToServiceScreen)
    }

    if (flutterApp && hostElementRef.current) {
      const vehicleData = {
        id: vehicle.id,
        tire_tread_fl: vehicle.tire_tread_fl,
        tire_tread_fr: vehicle.tire_tread_fr,
        tire_tread_rl: vehicle.tire_tread_rl,
        tire_tread_rr: vehicle.tire_tread_rr,
        tread_depth_check_date: vehicle.tread_depth_check_date,
        shop_details: vehicle.shop_details
      }
      flutterApp.addView({
        hostElement: hostElementRef.current,
        viewConstraints: {
          minHeight: 0,
          maxHeight: Infinity
        },
        initialData: {
          widget: "tread_depth",
          data: JSON.stringify(vehicleData)
        }
      })
    }
  }, [])

  return (
    <div style={{ border: "1px solid #ccc", padding: "15px" }}>
      <div ref={hostElementRef} />
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="tiny"
      >
        <Modal.Header>{i18n.t("vehicle:tireTreadDepth")}</Modal.Header>
        <Modal.Content>
          <TreadDepthModal />
        </Modal.Content>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { flutterApp } = state.application

  return {
    flutterApp: flutterApp,
    vehicle: state.vehicles.vehicle
  }
}

export default withRouter(connect(mapStateToProps)(TreadDepth))
