import React, { Component } from "react"
import { connect } from "react-redux"
import Store from "./actions/configureStore"

class FlutterInit extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let flutterApp = this.props.flutterApp
    let that = this
    document.addEventListener("DOMContentLoaded", (event) => {
      window._flutter.loader.load({
        // serviceWorkerSettings: {
        //   serviceWorkerVersion: null
        // },
        onEntrypointLoaded: async function (engineInitializer) {
          const appRunner = await engineInitializer.initializeEngine({
            multiViewEnabled: true
          })
          flutterApp = await appRunner.runApp()
          Store.dispatch({ type: "SET_FLUTTER_APP_SAGA", payload: flutterApp })
        }
      })
    })
  }

  componentWillUnmount() {
    Store.dispatch({ type: "SET_FLUTTER_APP_SAGA", payload: null })
  }

  render() {
    return <></>
  }
}

const mapStateToProps = (state) => {
  const flutterApp = state.application.flutterApp
  return {
    flutterApp: flutterApp || null
  }
}

export default connect(mapStateToProps)(FlutterInit)
